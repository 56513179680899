import { IOnChangesObject } from 'angular';
import * as rx from '@proftit/rxjs';
import { getCompChange } from './get-comp-change';

export function observeShareCompChange<T>(
  onChanges$: rx.Observable<IOnChangesObject>,
  propName: string,
): rx.Observable<T> {
  return rx.pipe(
    () => onChanges$,
    getCompChange<T>(propName),
    rx.shareReplay({ bufferSize: 1, refCount: true }),
  )(null);
}
