import type { IScope, IAngularEvent } from 'angular';
import * as rx from '@proftit/rxjs';
import type { ComponentLifecyclesObservables } from './component-livecycles-observables';
import { fromNgScopeEvent } from './from-ng-scope-event';

/*
 * Hook for observing angularjs scope event.
 *
 * @param livecycles - Source component lifecycles as observables.
 * @param $scope - Source compoent scope.
 * @param eventName
 * @tapFn - Side effect work function to activate when event is trigger.
 *
 * @returns - Object includes scope event observable.
 */
export function observeNgScopeEvent(
  lifecycles: ComponentLifecyclesObservables,
  $scope: IScope,
  eventName: string,
  tapFn: (event: IAngularEvent, args: any[]) => void,
): { scopeEvent$: rx.Observable<{ event: IAngularEvent; args: any[] }> } {
  const scopeEvent$ = fromNgScopeEvent($scope, eventName);

  scopeEvent$
    .pipe(
      rx.tap(({ event, args }) => {
        if (tapFn) {
          tapFn(event, args);
        }
      }),
      rx.takeUntil(lifecycles.onDestroy$),
    )
    .subscribe();

  return { scopeEvent$ };
}
