import { IScope } from 'angular';
import * as rx from '@proftit/rxjs';

export function fromNgScopeEvent($scope: IScope, eventName: string) {
  return rx.Observable.create((subscriber) => {
    const deregister = $scope.$on(eventName, (event, ...args) => {
      subscriber.next({ event, args });
    });

    $scope.$on('$destroy', () => {
      subscriber.complete();
      deregister();
    });

    return () => deregister();
  });
}
