import { IOnChangesObject, IController } from 'angular';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { ComponentLifecyclesObservables } from './component-livecycles-observables';

export function observeComponentLifecycles(
  contextP: IController,
): ComponentLifecyclesObservables {
  const context = contextP;
  const origOnInit = context.$onInit;
  const origOnDestroy = context.$onDestroy;
  const origOnChanges = context.$onChanges;
  const origPostLink = context.$postLink;

  if ([origOnInit, origOnDestroy, origOnChanges].some((x) => _.isNil(x))) {
    throw new Error('All lifecycles must be implemented');
  }

  const onInit$ = new rx.Subject<void>();
  const onInitShared$ = new rx.BehaviorSubject<boolean>(false);
  const onDestroy$ = new rx.Subject<void>();
  const onChanges$ = new rx.Subject<IOnChangesObject>();
  const onPostLink$ = new rx.Subject<void>();

  context.$onInit = (): void => {
    origOnInit.call(context);

    onInit$.next();
    onInitShared$.next(true);
  };

  context.$onDestroy = (): void => {
    origOnDestroy.call(context);

    onDestroy$.next();
    onDestroy$.complete();
  };

  context.$onChanges = (changesObj): void => {
    origOnChanges.call(context, changesObj);

    onChanges$.next(changesObj);
  };

  context.$postLink = (): void => {
    if (origPostLink) {
      origPostLink.call(context);
    }

    onPostLink$.next();
  };

  return {
    onInit$,
    onInitShared$,
    onDestroy$,
    onChanges$,
    onPostLink$,
  };
}
