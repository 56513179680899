import RestService from '~/source/common/services/rest';

const COMPLIANCE_STATUS_RESOURCE = 'verificationStatus';
const SERVICE_RESOURCE = 'customers';

export class GamingComplianceStatusService extends RestService {
  get resource() {
    return SERVICE_RESOURCE;
  }

  getApplicantsResource(customerId: number) {
    return this.resourceBuildStart()
      .getElement(customerId)
      .getNestedCollection(COMPLIANCE_STATUS_RESOURCE)
      .resourceBuildEnd();
  }

  getApplicantByCustomerId(customerId: number) {
    return this.getApplicantsResource(customerId).customGetWithQuery();
  }
}

export default GamingComplianceStatusService;
