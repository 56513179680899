import angular from 'angular';

import ComplianceComponent from './compliance/compliance.component';
import ComplianceAttachmentComponent from './compliance-attachment/compliance-attachment.component';
import ContactCompliancePreviewDialogComponent from './contact-compliance-preview-dialog/contact-compliance-preview-dialog.component';
import ComplianceDocCellRendererComponent from './compliance-doc-cell-renderer/compliance-doc-cell-renderer.component';
import { AttachmentQuestionnaireCustomerScoresCardComponent } from './attachment-questionnaire-customer-scores-card/attachment-questionnaire-customer-scores-card.component';
import { GamingComplianceStatusComponent } from '~/source/contact/contact-page/sidebar-tabs/compliance/gaming-compliance-status/gaming-compliance-status.component';
import GamingComplianceStatusService from '~/source/contact/common/services/gaming-compliance-status.service';

const ngModule = angular
  .module('crm.contact.sidebar.compliance', [])
  .component('prfContactCompliance', ComplianceComponent)
  .component('prfContactComplianceAttachment', ComplianceAttachmentComponent)
  .component(
    'prfContactCompliancePreviewDialog',
    ContactCompliancePreviewDialogComponent,
  )
  .component(
    'prfAttachmentQuestionnaireCustomerScoresCard',
    AttachmentQuestionnaireCustomerScoresCardComponent,
  )
  .component('prfComplianceDocCellRenderer', ComplianceDocCellRendererComponent)
  .component('prfGamingComplianceStatus', GamingComplianceStatusComponent)
  .service('gamingComplianceStatusService', GamingComplianceStatusService);

export default ngModule.name;
