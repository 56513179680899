import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1/src';
import { StateParams } from '@uirouter/core/lib/params/stateParams';
import template from './gaming-compliance-status.component.html';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { Customer } from '@proftit/crm.api.models.entities';
import { GamingComplianceStatus } from '@proftit/crm.api.models.entities';
import GamingComplianceStatusService from '~/source/contact/common/services/gaming-compliance-status.service';
const styles = require('./gaming-compliance-status.component.scss');
import log from 'loglevel';
import AppConfig from '~/source/conf/appConfig';

export class GamingComplianceStatusController {
  lifecycles = observeComponentLifecycles(this);
  customer: IElementRestNg<Customer>;
  data: GamingComplianceStatus;
  styles = styles;

  /* @ngInject */
  constructor(
    readonly $stateParams: StateParams,
    readonly gamingComplianceStatusService: GamingComplianceStatusService,
    readonly appConfig: typeof AppConfig,
  ) {}

  async $onInit() {
    await this.getComplianceStatusByCustomerId(this.customer.id);
  }

  async getComplianceStatusByCustomerId(customerId: number) {
    this.gamingComplianceStatusService
      .getApplicantByCustomerId(customerId)
      .then((res) => (this.data = res))
      .catch((error: Error) => {
        log.error(`${error.toString()}`);
      });
  }

  openSumsub() {
    const applicantId = this.data?.applicantId;
    const clientId = this.data?.clientId;

    if (!applicantId || !clientId) {
      log.warn('Missing applicantId or clientId for SUMSUB link');
      return;
    }

    const baseUrl = this.appConfig.sumsub_api;
    const url = `${baseUrl}/checkus/#/applicant/${applicantId}/basicInfo?clientId=${clientId}`;
    window.open(url, '_blank');
  }

  $onDestroy() {}

  $onChanges() {}
}

export const GamingComplianceStatusComponent = {
  bindings: {
    customer: '<',
  },
  controller: GamingComplianceStatusController,
  template,
};
