import { IOnChangesObject } from 'angular';
import * as rx from '@proftit/rxjs';
import { filterCompChange } from './filter-comp-change';

export function getCompChange<T>(propName: string) {
  return function operator(
    obs$: rx.Observable<IOnChangesObject>,
  ): rx.Observable<T> {
    return rx.pipe(
      () => obs$,
      filterCompChange<T>(propName),
      rx.map(a => a.currentValue),
    )(null);
  };
}
